import React from "react";
import "./App.scss";

function App() {
    return (
        <body>
            <h1>Neko's PJSK Utils</h1>
        </body>
    );
}

export default App;
